<template>
    <search-list-facet
        class="keywords-filter"
        title="Keywords"
        key="keyword-facet">
        <input
            type="text"
            slot="header"
            class="keywords-filter__typeahead w-100 form-control form-control-sm secondary test-b-form-input"
            placeholder="Filter keywords..."
            @keyup="handleTypeaheadKeyup" />

        <b-form-checkbox-group
            ref="ontoFilterList"
            stacked
            class="pt-2 pb-1"
            slot="body"
            v-model="selectedKeywords"
            :options="filteredKeywords"
            @change="applyKeywordsFilter" />
    </search-list-facet>
</template>

<script>
import ontology from '../../../api/ontology';
import SearchListFacet from '@/components/ui/common/SearchListFacet';

export default {
    name: 'KeywordsFilter',
    data() {
        return {
            keywords: [],
            selectedKeywords: [],
            filteredKeywords: [],
            keywordsFilter: '',
            fetchedKeywords: {},
        };
    },

    beforeMount() {
        this.getKeywords();
    },

    watch: {
        '$store.getters.filters.ontologies': {
            handler(val, oldVal) {
                const differences = oldVal.filter(
                    (element) => !val.includes(element)
                );
                for (let ontologyId of differences) {
                    if (
                        this.fetchedKeywords[ontologyId] &&
                        this.fetchedKeywords[ontologyId].ontologyKeywords
                            ?.length
                    ) {
                        for (let keyword of this.fetchedKeywords[ontologyId]
                            .ontologyKeywords) {
                            this.selectedKeywords =
                                this.selectedKeywords.filter(
                                    (selectedKeyword) =>
                                        selectedKeyword !== keyword
                                );
                        }
                    }
                }
            },
        },
    },

    methods: {
        /**
         * Makes an API call to retrieve keywords and assigns them to the keyword data property.
         */
        getKeywords() {
            ontology.getAllKeywords().then((response) => {
                response.data.forEach((keyword) => {
                    this.keywords.push({ text: keyword, value: keyword });
                });

                this.filteredKeywords = this.keywords;
            });
        },

        /**
         * When keywords are selected selects also the ontologies keywords belongs to.
         */
        async applyKeywordsFilter() {
            await this.$nextTick();

            const response = await ontology.filterByKeywords(
                this.selectedKeywords
            );

            const ontologies = response.data;

            if (!ontologies.length) {
                this.$store.getters.filters.ontologies = [];
            } else {
                const ontologiesId = [];
                ontologies.forEach((ontology) => {
                    if (this.$store.getters.ontoIndex[ontology.ontologyId]) {
                        ontologiesId.push(ontology.ontologyId);
                        this.fetchedKeywords[ontology.ontologyId] = ontology;
                    }
                });
                this.$store.getters.filters.ontologies = ontologiesId;
            }
        },

        /**
         * Handler for typeahead keyup event.
         * @param {KeyboardEvent} event
         */
        handleTypeaheadKeyup(event) {
            this.keywordsFilter =
                event.target.value && event.target.value.toLowerCase();
            this.filteredKeywords = this.getFilteredKeywords;
        },
    },

    computed: {
        /**
         * Returns the keywords that contain the typeahead string value.
         * @returns {Array<Object>}
         */
        getFilteredKeywords() {
            if (!this.keywordsFilter) return this.keywords;

            return this.keywords.filter((keyword) => {
                return keyword.text.toLowerCase().includes(this.keywordsFilter);
            });
        },
    },
    components: {
        SearchListFacet,
    },
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';

.keywords-filter {
    &__typeahead {
        box-shadow: none !important;
        padding-right: 1.6rem;

        &.secondary {
            &:hover,
            &:focus {
                border-color: rgba($secondary, 0.3) !important;
            }
        }
    }
}
</style>
