<template>
    <b-pagination-nav
        align="left"
        prev-text="Prev"
        next-text="Next"
        :class="{ 'loading-page': isLoading }"
        :current-page="currentPage"
        :link-gen="linkGen"
        :number-of-pages="numberPages"
        :limit="pageButtonsMax">
    </b-pagination-nav>
</template>

<script>
export default {
    name: 'Pagination',

    props: {
        pageButtonsMax: {
            type: Number,
            default: process.env.PAGE_BUTTONS_MAX,
        },

        pageSlug: {
            type: String,
            default: '/page',
        },

        numberPages: {
            type: Number,
            required: true,
        },

        currentPage: {
            type: Number,
            default: 1,
        },

        isLoading: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        linkGen(pageNum) {
            return {
                name: this.$route.name,
                params: Object.assign({}, this.$route.params, {
                    number: pageNum,
                }),
            };
        },
    },
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';

.pagination {
    display: inline-block;
}
::v-deep .page-link {
    color: $primary !important;
}

::v-deep .page-item {
    display: inline-block;
    vertical-align: middle;

    @media (min-width: 576px) {
        &.d-sm-flex {
            display: inline-block !important;
        }
    }

    &:first-of-type,
    &:last-of-type {
        .page-link {
            font-size: 0;
            padding: 1rem 0;
        }

        .page-link:before {
            content: '';
            width: 0;
            height: 0;
            border: 10px solid transparent;
            border-left: 10px solid;
            border-right: none;
            margin: 7px 14px 7px 10px;
            box-shadow: 0.2rem 0;
        }
    }

    &:first-of-type .page-link {
        transform: rotate(180deg) !important;
    }

    .page-link {
        border-width: 0;
        box-shadow: none;

        &[role='menuitem'] {
            color: $secondary;
            background: transparent;
            transition: $btn-transition;

            &:hover {
                color: $primary;
            }
        }

        &[role='menuitemradio'] {
            color: inherit;
            border-bottom-width: 0.2rem;
            border-bottom-color: transparent;
        }
    }

    &:not(.active) [role='menuitemradio']:hover {
        background-color: rgba($primary, 0.35);
        border-bottom-color: rgba($primary, 0.35);
    }

    &.active .page-link {
        background-color: transparent;
        color: $link-hover-color;
        font-weight: 500;
        border-bottom-width: 0.2rem;
        border-bottom-color: $link-hover-color !important;

        .loading-page & {
            color: inherit;
            @include pulse-border-animation;
        }
    }

    &.disabled .page-link {
        color: $gray-400;
    }
}

@include pulse-border-keyframes($link-hover-color);
</style>
