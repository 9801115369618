<template>
    <div class="search-list-facet">
        <b-dropdown-header class="search-list-facet-title d-flex">
            <span class="flex-grow-1 text-light search-list-facet__text">{{
                title
            }}</span>

            <b-button
                class="collapse-btn ml-3 p-0 border-0 bg-transparent"
                @click="expanded = !expanded">
                <font-awesome-icon
                    :icon="expanded ? 'minus' : 'plus'"
                    class="align-middle" />
            </b-button>
        </b-dropdown-header>

        <div class="search-list-facet-header" v-if="!!$slots.header">
            <slot name="header" />
        </div>

        <div
            v-show="expanded"
            class="search-list-facet-body"
            v-if="!!$slots.body">
            <slot name="body" />
        </div>

        <div class="search-list-facet-footer" v-if="!!$slots.footer">
            <slot name="footer" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'SearchFacet',
    props: {
        title: {
            type: String,
            required: true,
        },
        defaultStateExpanded: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            expanded: this.defaultStateExpanded,
        };
    },
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';

.search-list-facet {
    background: darken($white, 2%);

    &-title {
        color: #fff;
        background: $secondary;
    }

    &-header,
    &-footer {
        margin: 0.5rem;
        background: rgba($dark, 0.025);
    }

    &-body {
        padding: 4px 20px;
        margin-bottom: 20px;
        background: rgba($dark, 0.025);
    }

    .collapse-btn {
        margin-top: -0.4rem;
        opacity: 0.6;

        &:hover {
            opacity: 1;
        }
    }

    .dropdown-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0.5rem 1rem;
        .dropdown-header p {
            line-height: 1.35rem;
        }
    }
}
</style>
