<template>
    <div
        class="pb-5"
        :class="{
            'main-spacer': query,
            wrapper: !query,
            'active-terms': !this.$store.getters.isObsolete,
            'obsolete-terms': this.$store.getters.isObsolete,
            'fuzzy-match': !this.$store.getters.isExact,
            'exact-match': this.$store.getters.isExact,
            'relation-match': this.$store.getters.searchType === 'property',
            'class-match': this.$store.getters.searchType === 'class',
        }">
        <dl v-if="query" class="result-area d-flex flex-row">
            <transition-group
                name="result-facets"
                tag="div"
                class="flex-column pr-3 border-right">
                <onto-filter
                    class="result-facet mb-3"
                    key="onto-facet"
                    :ontoEntriesMax="10"
                    :isOntoFilterShown="isOntoFacet"
                    @filter:close="isOntoFacet = false"
                    @filter:open="isOntoFacet = true" />
                <search-settings
                    class="result-facet"
                    key="settings-facet"
                    :isSettingsShown="isSettingsFacet"
                    @filter:close="isSettingsFacet = false"
                    @filter:open="isSettingsFacet = true" />

                <keywords-filter key="keyword-facet" />
            </transition-group>

            <div
                v-if="!firstLoad"
                class="result-list col ml-3 flex-column"
                :class="{ 'd-flex': !results.length }">
                <h5 class="selected-facets">
                    <small class="result-count text-muted">
                        <strong>{{ total }}</strong>
                        terms found
                    </small>
                    <template v-if="$store.getters.filters.ontologies.length">
                        <b-badge
                            v-for="ontoID in $store.getters.filters.ontologies"
                            class="mx-1"
                            :key="ontoID"
                            v-b-tooltip.hover="{
                                title:
                                    $store.getters.ontoData(ontoID)
                                        .ontologyLongDisplayName || ontoID,
                                delay: { show: showDelay, hide: 0 },
                            }">
                            {{
                                $store.getters.ontoData(ontoID)
                                    .ontologyShortDisplayName || ontoID
                            }}
                            <b-button-close
                                class="ml-2"
                                text-variant="light"
                                @click="onOntoRemove(ontoID)" />
                        </b-badge>
                    </template>
                    <b-badge v-else variant="light" class="text-muted"
                        >No filters applied</b-badge
                    >
                </h5>

                <template v-if="results.length">
                    <pagination
                        v-if="numberPages > 1"
                        :isLoading="isLoading"
                        :current-page="currentPage"
                        :number-pages="numberPages" />
                    <search-item
                        v-for="(item, index) in results"
                        class="mx-2 mt-4"
                        :key="item.id"
                        :item="item"
                        :isObsolete="
                            !isLoading && $store.getters.filters.isObsolete
                        "
                        :searchType="$store.getters.filters.searchType"
                        :testId="index">
                    </search-item>
                    <pagination
                        v-if="numberPages > 1"
                        :isLoading="isLoading"
                        :current-page="currentPage"
                        :number-pages="numberPages" />
                </template>

                <div
                    v-else
                    class="not-found mt-2 flex-grow-1 d-flex flex-column justify-content-center">
                    <h4 class="text-secondary d-flex align-items-center">
                        <font-awesome-icon icon="info-circle" size="2x" />
                        <span class="pl-3"
                            >Sorry, we couldn't find any results for &quot;{{
                                query
                            }}&quot;</span
                        >
                    </h4>
                    <template v-if="$store.getters.hasOntoIndex">
                        <h5>Search tips</h5>
                        <ul class="mb-4">
                            <li v-if="$store.getters.filters.isExact">
                                Exact search is enabled. You can switch to
                                <b-link class="text-primary" @click="onFuzzy">
                                    <fuzzy-icon class="svg-icon" /> fuzzy
                                    search.
                                </b-link>
                            </li>
                            <li>
                                Broaden your search by removing filters or
                                changing your query.
                            </li>
                            <li>Check your spelling and try again.</li>
                            <li>Try using synonyms.</li>
                            <li>
                                <b-link to="/ontologies">Check</b-link> that all
                                the necessary ontologies are loaded.
                            </li>
                        </ul>
                        <h5>Help us improve {{ appName }}</h5>
                        <p>
                            <b-link
                                rel="external"
                                target="_blank"
                                :href="helpUrl"
                                >Report</b-link
                            >
                            the unmatched query if you think it should be
                            included in the database.
                        </p>
                    </template>
                    <template v-else>
                        <h5>No ontologies have been loaded yet.</h5>
                        <p>
                            You can choose which ones should be loaded on the
                            <b-link to="/ontologies"
                                >ontologies index page</b-link
                            >.
                        </p>
                    </template>
                </div>
            </div>
        </dl>
        <div class="loading centered" v-if="firstLoad"></div>
    </div>
</template>

<script>
import SearchItem from '@/components/SearchList/SearchItem';
import OntoFilter from '@/components/ui/OntoFilter';
import SearchSettings from '@/components/ui/SearchSettings';
import KeywordsFilter from '@/components/ui/SearchList/KeywordsFilter';
import Pagination from '@/components/ui/Pagination';
import FuzzyIcon from '@/components/ui/FuzzyIcon';
import ApiSearch from '@/api/search';

export default {
    name: 'SearchList',
    components: {
        SearchItem,
        OntoFilter,
        SearchSettings,
        Pagination,
        FuzzyIcon,
        KeywordsFilter,
    },

    props: {
        showDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SHOW_DELAY),
        },

        pageSize: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SEARCH_SIZE),
        },

        helpUrl: {
            type: String,
            default: process.env.VUE_APP_HELP_URL,
        },

        appName: {
            type: String,
            default: process.env.VUE_APP_NAME,
        },
    },

    data() {
        return {
            query: '',
            results: [],
            total: 0,
            from: 0,
            currentPage: 1,
            firstLoad: true,
            isLoading: true,
            isOntoFacet: true,
            isSettingsFacet: true,
            unwatchFilters: null,
        };
    },

    computed: {
        numberPages: function () {
            return Math.ceil(this.total / this.pageSize);
        },
    },

    watch: {
        $route(to, from) {
            this.onRouteParam(to.params);

            if (!this.query || !this.results.length) {
                this.firstLoad = true;
            }
        },

        '$store.getters.filters.ontologies': function () {
            this.updateSearchFilters();
        },
        '$store.getters.filters.isExact': function () {
            this.updateSearchFilters();
        },
        '$store.getters.filters.isObsolete': function () {
            this.updateSearchFilters();
        },
        '$store.getters.filters.searchType': function () {
            this.updateSearchFilters();
        },

        results: function () {
            window.scrollTo(0, 0);
        },
    },

    created() {
        this.onRouteParam(this.$route.params);

        // Checks if the Open id authcode is still the in url, if it is then remove it
        if (this.urlHasAuthCode) {
            this.removeAuthCode();
        }
    },

    methods: {
        updateSearchFilters() {
            // No syncing is necessary when not on the results page
            if (this.$route.params.query) {
                this.fetchResults(
                    this.query,
                    this.$store.getters.filters.ontologies
                ).then(() => {
                    if (this.$router.currentRoute.name !== 'search-page-n')
                        return;
                    // Prevents sudden jump to the cover page if the search field is empty, restoring the previous query in that case.
                    if (this.$store.getters.query.length) {
                        this.$router.replace({
                            name: 'search-page-n',
                            params: { query: this.$store.getters.query },
                        });
                    } else {
                        this.$eventBus.$emit('typeahead:set', this.query);
                    }
                });
            }
        },

        onRouteParam(params) {
            const query = params.query;
            const pageNum = parseInt(params.number || '1');

            if (query && query.trim().length) {
                this.query = query.trim();
                this.fetchResults(
                    this.query,
                    this.$store.getters.filters.ontologies,
                    pageNum
                );
            } else {
                this.query = '';
                this.results = [];
            }
        },

        urlHasAuthCode() {
            const urlParams = new URLSearchParams(window.location.search);
            const authCode = urlParams.get('code');
            return !!authCode;
        },

        removeAuthCode() {
            window.history.replaceState(null, null, window.location.pathname);
        },

        fetchResults(query, ontologies, currentPage = 1) {
            const params = {
                query,
                ontologies,
                isExact: this.$store.getters.isExact,
                isObsolete: this.$store.getters.isObsolete,
                searchType: this.$store.getters.searchType,
            };

            if (currentPage > 1) {
                params.pageSize = this.pageSize;
                params.pageStart = (currentPage - 1) * this.pageSize;
            }

            this.currentPage = currentPage;
            this.isLoading = true;

            return ApiSearch.results(params)
                .then((response) => {
                    this.results = response.data.elements;
                    this.total = response.data.total;
                    this.from = response.data.from;
                    document.title = `${process.env.VUE_APP_NAME} | ${
                        this.query || 'search'
                    }`;
                    this.$store.currTitle = document.title;
                })
                .finally(() => {
                    this.firstLoad = false;
                    this.isLoading = false;
                });
        },

        onFuzzy() {
            this.$store.dispatch('exact', false);
        },

        onOntoRemove(ontoID) {
            const ontologies = [...this.$store.getters.filters.ontologies];
            const index = ontologies.indexOf(ontoID);
            ontologies.splice(index, 1);
            this.$store.getters.filters.ontologies = ontologies;
        },
    },
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';

.result-count {
    display: block;
    margin: 0 0.4rem;
    padding: 0.25rem 0;

    @media (min-width: 1024px) {
        float: right;
    }
}

.fuzzy-icon {
    fill: currentColor;
    height: 0.95em;
}

.result-facets-move {
    transition: transform 0.3s ease-in;
}

.result-facet {
    margin-bottom: 20px;
}

.result-area {
    max-width: 2560px;
    margin: 0 auto;

    .result-list {
        min-width: 768px;

        .selected-facets {
            margin-bottom: 1.2rem;

            .close {
                line-height: 1rem;
                margin-top: -0.25rem;
            }
        }

        .search-item {
            margin-bottom: 1.9rem;
        }

        .not-found {
            min-width: 300px;
            max-width: 500px;
            width: 60%;
            margin: 0 auto;

            h4 {
                margin: 1rem auto 2rem;
                font-weight: 400;
            }

            a {
                font-weight: 500;
            }
        }
    }
}

.wrapper {
    @include screen-centered;
    @include dark-background('../assets/hero.jpg');
    filter: blur(4px);
    * {
        display: none;
    }
}

.loading:after {
    content: 'Searching...';
}
</style>
