<template>
    <div class="search-item">
        <!-- Title -->
        <class-name
            class="title-container h5 mb-1 d-block"
            ref="titleContainer"
            :isOntoBadge="true"
            :isSummary="false"
            :isObsolete="isObsolete"
            :ontologyID="item.sourceUniqueID"
            :primaryID="item.primaryID"
            :isLink="!isObsolete"
            :initClass="(item.tree = tree) && item">
            <text-actions
                slot="actions"
                :copyTargetName="humanReadable('shortFormIDs')"
                :copyTargetEl="
                    () =>
                        $refs.titleContainer &&
                        $refs.titleContainer.$refs.shortID[0]
                "
                :searchTargetEl="
                    () =>
                        $refs.titleContainer &&
                        $refs.titleContainer.$refs.termName[0]
                "
                searchTargetName="term"
                @mouseenter.native.stop="
                    $refs.titleContainer.$el.classList.add(
                        'class-actions-hovered'
                    )
                "
                @mouseleave.native.stop="
                    $refs.titleContainer.$el.classList.remove(
                        'class-actions-hovered'
                    )
                " />
        </class-name>

        <!-- Primary ID -->
        <class-summary
            ref="primaryID"
            class="primary-container"
            :primaryID="item.primaryID"
            :serialPropNames="['primaryID']"
            :propLengthMax="[0]"
            :isShowBadges="false">
            <text-actions
                class="ml-1"
                :copyTargetName="humanReadable('primaryID')"
                :copyTargetEl="
                    () => $refs.primaryID && $refs.primaryID.$refs.propValue[0]
                "
                :url="item.primaryID"
                :searchTargetName="humanReadable('primaryID')"
                :searchTargetEl="
                    () => $refs.primaryID && $refs.primaryID.$refs.propValue[0]
                " />
        </class-summary>

        <!-- Other properties -->
        <class-summary
            v-bind="item"
            :serialPropNames="summaryPropNames"
            :propLengthMax="summaryPropLengths"
            :isPlaceholder="false"
            :isObsolete="isObsolete"
            :isPopover="true">
            <span class="usage d-block">
                <strong class="text-muted"
                    >Usage<span class="font-weight-normal">:</span></strong
                ><!--
                --><class-name
                    v-if="!isNoUsage.value"
                    :ontologyID="item.sourceUniqueID"
                    :primaryID="item.primaryID"
                    :isLink="true"
                    :isGlobalScope="true"
                    :isSelf="false"
                    :initClass="item"
                    @class:unresolved="onUnresolved" />
                <span v-else class="ml-1 text-muted"
                    >not found outside
                    {{ item && item.shortDisplayName }}.</span
                >
            </span>
        </class-summary>

        <!-- More details -->
        <template v-if="!isObsolete && !isRootClass && searchType === 'class'">
            <b-btn
                class="border-0 p-0"
                size="sm"
                variant="link-secondary"
                :class="{ expanded: isShowTree }"
                :disabled="loading"
                @click="onToggleTree">
                <span v-if="loading" class="loading ml-1"></span>
                <template v-else>
                    <font-awesome-icon icon="chevron-circle-right" />
                    {{
                        isShowTree && searchType === 'class' ? 'Hide' : 'Show'
                    }}
                    tree paths
                </template>
            </b-btn>

            <b-collapse
                class="text-center"
                v-model="isAsyncShowTree"
                :id="`item-${item.id}`">
                <hierarchy-tree
                    v-if="isShowTree"
                    :nodes="tree"
                    :isEditable="false"
                    :isSelectable="false"
                    :initFilterFuzzy="false"
                    :initFilterDisplay="'list'"
                    :initFilterQuery="
                        item.shortFormIDs && item.shortFormIDs[0]
                    " />
            </b-collapse>
        </template>
    </div>
</template>

<script>
import { isEmpty, without } from 'lodash';

import ClassName from '@/components/ui/ClassName';
import TextActions from '@/components/ui/TextActions';
import ClassSummary from '@/components/ui/ClassSummary';
import HierarchyTree from '@/components/ui/HierarchyTree';

import ApiOntology from '@/api/ontology';
import { ANNOTATION_PROPS } from '@/config';

export default {
    name: 'SearchItem',
    components: {
        ClassName,
        TextActions,
        ClassSummary,
        HierarchyTree,
    },

    props: {
        item: {
            type: Object,
            required: true,
        },

        isObsolete: {
            type: Boolean,
            default: false,
        },
        searchType: {
            type: String,
            default: 'class',
        },

        summaryPropNames: {
            type: Array,
            default: function () {
                return without(ANNOTATION_PROPS, 'mappings');
            },
        },

        summaryPropLengths: {
            type: Array,
            default: function () {
                return [310, 200];
            },
        },
    },

    data() {
        return {
            tree: {},

            // Lazy loading feedback
            loading: false,

            // True if tree info is on display
            isShowTree: false,

            // True if the class doesn't occur in any other ontology
            // NOTE: Prevents memoisation by nested component by means of an object.
            isNoUsage: { value: false },
        };
    },

    computed: {
        isRootClass: function () {
            return this.item.superClasses && !this.item.superClasses.length;
        },

        // Only resolves to true once the tree has been successfully retrieved from the server
        isAsyncShowTree: {
            get() {
                return !this.loading && this.tree.leaves && this.isShowTree;
            },

            set(value) {
                return value;
            },
        },
    },

    watch: {
        isNoUsage: 'reset',
    },

    methods: {
        fetchTree(params) {
            this.loading = true;

            ApiOntology.pathsFromRoot(params)
                .then((response) => {
                    this.tree = response.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        onToggleTree() {
            this.isShowTree = !this.isShowTree;

            if (isEmpty(this.tree)) {
                this.fetchTree({
                    ontologyID: this.item.entityUniqueID,
                    classID: this.item.id,
                });
            }
        },

        reset() {
            this.isNoUsage.value = false;
        },

        onUnresolved() {
            this.isNoUsage.value = true;
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../scss/variables';

.title-container,
.primary-container {
    .text-actions {
        opacity: 0;
    }
}
.title-container,
.primary-container {
    &:hover .text-actions {
        opacity: 1;
    }
}

.title-container.class-actions-hovered ::v-deep .name-label {
    color: $secondary;
}

.primary-container ::v-deep .summary-property {
    display: inline;
}

.usage {
    font-size: 90%;

    .text-muted:first-child {
        user-select: none;
    }

    ::v-deep .global-badge {
        font-size: 0.7rem;
    }
}

.btn {
    text-decoration: none !important;
    color: lighten($secondary, 8%);

    &.expanded {
        color: $link-hover-color !important;
    }

    &:hover {
        color: $secondary;
    }
}

.fa-chevron-circle-right {
    transition: $modal-transition;

    .expanded & {
        transform: rotate(90deg);
    }
}

.loading {
    position: relative;
    display: inline-block;
    font-size: 1em;

    &:after {
        content: 'Retrieving paths...';
        font-weight: inherit;
        color: inherit;
    }
}

.filtered.filter-list ::v-deep .node-text-wrapper {
    max-width: 600px !important;
}

// Selection has been disabled non-consequential
::v-deep .tree-content:hover {
    cursor: default;
}
</style>
